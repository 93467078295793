import { Component, OnInit, Inject, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';

import { CloudService } from '../../services/cloud.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-delete-cloud',
    templateUrl: './delete-cloud.component.html',
    styleUrls: ['./delete-cloud.component.scss'],
    standalone: false
})
export class DeleteCloudComponent implements OnInit, OnDestroy {
    constructor(
        public dialogRef: MatDialogRef<DeleteCloudComponent>,
        private cloudService: CloudService,
        @Inject(MAT_DIALOG_DATA) private data: any
    ) {}

    /**
     * @param {EventEmitter} emet un evement pour ouvrir la fenetre d'erreur.
     * @param {boolean} setWaiting faut-il faire attendre la vue ou non.
     * @param {DELETE_CONFIRMATION} string phrase de confirmation a ecrire pour supprimer un cloud definitivement.
     */
    @Output() openErrorDialog: EventEmitter<string> = new EventEmitter();

    deleteCloud$: Subscription;

    setWaiting: boolean;
    deleteInput: string;

    DELETE_CONFIRMATION = 'SUPPRESSION DÉFINITIVE';

    ngOnInit() {
        this.setWaiting = false;
        this.deleteInput = '';
    }

    ngOnDestroy() {
        if (this.deleteCloud$) {
            this.deleteCloud$.unsubscribe();
        }
    }

    /**
     * Peut-on supprimer un cloud ou non ?
     * @returns {boolean} true si on peut.
     */
    canDelete() {
        return this.deleteInput.toUpperCase() === this.DELETE_CONFIRMATION;
    }

    /**
     * Supprime le cloud
     */
    deleteCloud() {
        if (this.setWaiting === false && this.canDelete()) {
            this.setWaiting = true;
            this.deleteCloud$ = this.cloudService.deleteCloud(this.data.cloudId).subscribe(
                () => {
                    this.cloudService.refreshClouds();
                    this.closeDialog();
                    this.setWaiting = false;
                },
                (error: HttpErrorResponse) => {
                    this.openErrorDialog.emit(error.error.userMessage);
                    this.setWaiting = false;
                }
            );
        }
    }

    /**
     * Ferme la fenêtre de création de cloud
     */
    closeDialog() {
        this.dialogRef.close();
    }
}
