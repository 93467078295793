import { Component, Inject, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { CloudService } from '../../services/cloud.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-mask-cloud',
    templateUrl: './mask-cloud.component.html',
    styleUrls: ['./mask-cloud.component.scss'],
    standalone: false
})
export class MaskCloudComponent implements OnDestroy {
    /**
     * @param {EventEmitter} openErrorDialog emet un evenement si il faut ouvrir la fenetre d'erreur.
     * @param {any} cloud le cloud en question.
     * @param {number} groupNumber le nombre de groupes inscrits au cloud.
     * @param {number} learnerNumber le nombre d'apprenants inscrits au cloud.
     * @param {number} teacherNumber le nombre de tuteurs inscrits au cloud.
     */
    @Output() openErrorDialog: EventEmitter<string> = new EventEmitter();

    maskCloud$: Subscription;

    cloud: any;

    groupNumber: number;
    learnerNumber: number;
    teacherNumber: number;
    roleNumber: number;

    constructor(
        public dialogRef: MatDialogRef<MaskCloudComponent>,
        private cloudService: CloudService,
        @Inject(MAT_DIALOG_DATA) private data: any
    ) {}

    ngOnDestroy() {
        if (this.maskCloud$) {
            this.maskCloud$.unsubscribe();
        }
    }

    /**
     * Ferme la fenetre.
     */
    closeDialog() {
        this.dialogRef.close();
    }

    maskCloud() {
        this.maskCloud$ = this.cloudService.maskCloud(this.data.cloudId).subscribe(
            (data: any) => {
                this.cloudService.refreshClouds();
                this.closeDialog();
            },
            (error: HttpErrorResponse) => {
                this.openErrorDialog.emit(error.error.userMessage);
            }
        );
    }
}
