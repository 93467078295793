import { Component, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'app-workspace-carousel',
    templateUrl: './workspace-carousel.component.html',
    styleUrls: ['./workspace-carousel.component.scss'],
    standalone: false
})
export class WorkspaceCarouselComponent implements OnInit, OnDestroy {
    private _pictureList: CarouselPicture[] = [
        {
            url: 'https://cdn.easi-training.fr/assets/img/cloud/intro_01.png',
            description:
                'Avec <strong>easi cloud</strong>, partagez simplement vos fichiers avec ' +
                'les autres utilisateurs d’<strong>easi</strong> !'
        },
        {
            url: 'https://cdn.easi-training.fr/assets/img/cloud/intro_02.png',
            description:
                '<strong>Collaborez et commentez</strong> en temps réel sur les documents partagés'
        },
        {
            url: 'https://cdn.easi-training.fr/assets/img/cloud/intro_03.png',
            description:
                'Et <strong>suivez l’historique</strong> des modifications... Tous les détails dans notre rubrique d’aide !'
        }
    ];

    public get pictureList(): CarouselPicture[] {
        return this._pictureList;
    }

    private _picURL: SafeResourceUrl = '';
    public get picURL(): string {
        return this._picURL as string;
    }
    public set picURL(value: string) {
        this._picURL = this.domSanitizer.bypassSecurityTrustResourceUrl(value);
    }

    private _picDescription: SafeHtml = '';
    public get picDescription(): string {
        return this._picDescription as string;
    }
    public set picDescription(value: string) {
        this._picDescription = this.domSanitizer.bypassSecurityTrustHtml(value);
    }

    private interval;

    currentIndex = 0;
    showingAnimation = true;
    changePicture = true;

    constructor(private domSanitizer: DomSanitizer) {}

    ngOnInit() {
        this._picURL = this.domSanitizer.bypassSecurityTrustResourceUrl(this.pictureList[0].url);
        this._picDescription = this.domSanitizer.bypassSecurityTrustHtml(
            this.pictureList[0].description
        );
        if (this.changePicture) {
            this.interval = setInterval(() => {
                if (this.changePicture) {
                    this.setNextPicture();
                }
                this.changePicture = true;
            }, 5000);
        }
    }

    ngOnDestroy(): void {
        clearInterval(this.interval);
    }

    private changePic() {
        this.showingAnimation = false;
        this.picURL = this.pictureList[this.currentIndex].url;
        this.picDescription = this.pictureList[this.currentIndex].description;
        setTimeout(() => {
            this.showingAnimation = true;
        }, 1);
    }

    setNextPicture() {
        this.changePicture = false;
        this.currentIndex =
            this.currentIndex + 1 >= this.pictureList.length ? 0 : this.currentIndex + 1;
        this.changePic();
    }

    setPreviousPicture() {
        this.changePicture = false;
        this.currentIndex =
            this.currentIndex - 1 < 0 ? this.pictureList.length - 1 : this.currentIndex - 1;
        this.changePic();
    }

    setCurrentPicture(index: number) {
        if (index < 0 || index >= this.pictureList.length) {
            return;
        }
        this.changePicture = false;
        this.currentIndex = index;
        this.changePic();
    }
}

interface CarouselPicture {
    url: string;
    description: string;
}
