import { Component, OnInit, Input, Output, EventEmitter, DoCheck, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Subscription } from 'rxjs';

import { DialogService } from '../../services/dialog.service';
import { CloudService } from '../../services/cloud.service';
import { Cloud } from '../../structures/cloud';

/**
 * Composant gérant l'affichage de la vue principal
 */
@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
    standalone: false
})
export class MainComponent implements OnInit, OnDestroy, DoCheck {
    subscriptions: Subscription = new Subscription();

    @Output() clear: EventEmitter<any> = new EventEmitter();

    /**
     * @param {any} currentCloud le cloud affiche dans l'application.
     * @param {boolean} reset indique si il faut reset la page ou non.
     */
    currentCloud: any;

    reset = false;

    currentCloudUrl: SafeResourceUrl;

    constructor(
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer,
        private dialogService: DialogService,
        private cloudService: CloudService
    ) {}

    ngOnInit() {
        this.subscriptions.add(
            this.route.queryParams.subscribe((params) => {
                if (params['fileurl']) {
                    this.currentCloud = {};
                    this.currentCloud.url = atob(params['fileurl']);
                }
                this.reset = true;
            })
        );

        this.subscriptions.add(
            this.route.paramMap.subscribe((params: ParamMap) => {
                if (params.get('id') && params.get('id') !== '-1' && params.get('id') !== '0') {
                    this.cloudService.getCloud(+params.get('id')).subscribe((cloud: Cloud) => {
                        this.currentCloud = cloud;
                        this.currentCloud.url = cloud.url;
                        this.reset = true;
                    });
                } else if (
                    params.get('id') === '0' &&
                    !this.route.snapshot.queryParamMap.get('fileurl')
                ) {
                    this.cloudService.getClouds('').subscribe((clouds) => {
                        if (clouds.root) {
                            this.currentCloud = {
                                id: 0,
                                url: clouds.root
                            };
                            this.reset = true;
                        }
                    });
                } else if (params.get('id') === '-1') {
                    this.cloudService.getClouds('').subscribe((clouds) => {
                        if (clouds.root) {
                            this.currentCloud = {
                                id: -1,
                                url: clouds.root + '&view=trashbin'
                            };
                            this.reset = true;
                        }
                    });
                }
            })
        );

        this.subscriptions.add(
            this.cloudService.resetCurrentCloud.subscribe(
                (deletedCloudId) => {
                    if (this.currentCloud.id === deletedCloudId) {
                        this.currentCloud = undefined;
                    }
                },
                (error: HttpErrorResponse) => {
                    this.dialogService.openErrorDialog(error.error.userMessage);
                }
            )
        );

        this.subscriptions.add(
            this.cloudService.refreshSingleCloud.subscribe(() => {
                this.reset = true;
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    /**
     * Lifecycle hook overridden to update the iframe only when its URL changes. Otherwise Angular updates it every time the context changes.
     * Uses SafeRessourceUrls to avoid errors (and security breaches).
     */
    ngDoCheck() {
        if (this.reset && this.currentCloud) {
            this.currentCloudUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
                this.currentCloud.url
            );
            this.reset = false;
        }
    }
}
