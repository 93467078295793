import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { UsersService } from '../../../services/users.service';
import { DialogService } from '../../../services/dialog.service';
import { Subscription } from 'rxjs';
import { slideToggle } from 'src/app/animations/slide-toggle.animation';

@Component({
    selector: 'app-members-entry',
    templateUrl: './members-entry.component.html',
    styleUrls: ['./members-entry.component.scss'],
    animations: [slideToggle],
    standalone: false
})
export class MembersEntryComponent implements OnInit, OnDestroy {
    /**
     * @param {string} type le type d'utilisateur en question.
     * @param {any} element le cloud en question.
     * @param {boolean} moderator si l'utilisateur est moderateur du cloud ou non.
     * @param {boolean} openedGroup si le groupe en question est ouvert ou non.
     */
    @Input() type: string;
    @Input() element: any;

    getGroup$: Subscription;

    rights: number;
    moderator: boolean;
    openedGroup: boolean;

    constructor(private usersService: UsersService, private dialogService: DialogService) {}

    ngOnInit() {
        this.openedGroup = false;
        this.rights = this.element.rights;
    }

    ngOnDestroy() {
        if (this.getGroup$) {
            this.getGroup$.unsubscribe();
        }
    }

    /**
     * Indique l'icône à afficher selon le type et le rôle de l'element actuel
     * @returns {string} La classe CSS correspondante à l'icône à afficher
     */
    getIconStyle(): string {
        if (this.type === 'role') {
            if (this.element.shortname === 'nationalAdmin') {
                return 'icon-adminnational';
            } else if (this.element.shortname === 'localAdmin') {
                return 'icon-adminlocal';
            } else if (this.element.shortname === 'internalTeacher') {
                return 'icon-formateurinterne';
            } else if (this.element.shortname === 'accountManager') {
                return 'icon-gestionnairedecomptes';
            } else if (this.element.shortname === 'contentManager') {
                return 'icon-gestionnairecontenuspayants';
            } else if (this.element.shortname === 'validator') {
                return 'icon-valideur';
            } else if (this.element.shortname === 'externalTeacher') {
                return 'icon-formateurexterne';
            } else if (this.element.shortname === 'corporationTeacher') {
                return 'icon-FormateurEntreprise';
            } else if (this.element.shortname === 'siteTeacher') {
                return 'icon-formateur-site';
            } else if (this.element.shortname === 'tutor') {
                return 'icon-tuteurentreprise';
            } else if (this.element.shortname === 'learner') {
                return 'icon-apprenant';
            }
        } else if (this.type === 'user') {
            return this.getUserIconStyle(this.element);
        } else if (this.type === 'group') {
            if (this.element.is_teacher) {
                return 'icon-formateurinterne';
            }
            return 'icon-groupe';
        }
    }

    getUserIconStyle(user) {
        if (user.roles.learner) {
            return 'icon-apprenant';
        } else if (user.roles.prospect) {
            return 'icon-apprenant-prospect';
        } else if (user.roles.internalTeacher) {
            return 'icon-formateurinterne';
        } else if (user.roles.externalTeacher) {
            return 'icon-formateurexterne';
        } else if (user.roles.siteTeacher) {
            return 'icon-formateur-site';
        } else if (user.roles.corporationTeacher) {
            return 'icon-FormateurEntreprise';
        } else if (user.roles.tutor) {
            return 'icon-tuteurentreprise';
        } else if (user.roles.localAdmin) {
            return 'icon-adminlocal';
        } else if (user.roles.nationalAdmin) {
            return 'icon-adminnational';
        }
    }

    /**
     * Renvoie le nom à afficher pour l'élement actuel
     * @returns {string} Renvoie le nom du group si l'élement est un groupe, ou le prénom et le nom si l'élement est un utilisateur
     */
    getElementName(): string {
        if (this.type === 'group' || this.type === 'role') {
            return this.element.name;
        } else {
            return this.element.lastname.toUpperCase() + ' ' + this.element.firstname;
        }
    }

    /**
     * Indique si l'élement actuel possède le rôle de modérateur
     * @returns {boolean} True si l'element actuel possède le rôle de modérateur, false dans le cas contraire
     */
    isModerator(): boolean {
        return this.element.role === 'moderator';
    }

    /**
     * Indique si le groupe est déplié
     */
    isGroupOpened() {
        return this.openedGroup;
    }

    /**
     * Déplie le groupe sélectionné
     * @param {event} $event L'évenement JS émi lors du clic
     */
    showGroup(): void {
        if (this.element.users === undefined) {
            this.getGroup$ = this.usersService.getGroup(this.element.id).subscribe(
                (data: any) => {
                    this.element = data;
                    this.openedGroup = !this.openedGroup;
                },
                (error: HttpErrorResponse) => {
                    this.dialogService.openErrorDialog(error.error.userMessage);
                }
            );
        } else {
            this.openedGroup = !this.openedGroup;
        }
    }
}
