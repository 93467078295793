import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { CloudService } from '../../../services/cloud.service';
import { DialogService } from '../../../services/dialog.service';
import { UsersService } from '../../../services/users.service';

import { Subscription } from 'rxjs';

@Component({
    selector: 'app-shared-clouds-entry',
    templateUrl: './shared-clouds-entry.component.html',
    styleUrls: ['./shared-clouds-entry.component.scss'],
    standalone: false
})
export class SharedCloudsEntryComponent implements OnInit, OnDestroy {
    @Input() cloud: any;
    @Input() selected: boolean;

    getCloud$: Subscription;
    getGroup$: Subscription;

    openedCloud: boolean;

    constructor(
        private cloudService: CloudService,
        private dialogService: DialogService,
        private userService: UsersService
    ) {}

    /**
     * @param {any} user Un membre du cloud actuel
     * Indique l'icône à afficher selon le type et le rôle de l'utilisateur
     * @returns {string} La classe CSS correspondante à l'icône à afficher
     */
    getUserIcon(user: any): string {
        if (user.roles.learner) {
            return 'icon-apprenant';
        } else if (user.roles.prospect) {
            return 'icon-apprenant-prospect';
        } else if (user.roles.internalTeacher) {
            return 'icon-formateurinterne';
        } else if (user.roles.externalTeacher) {
            return 'icon-formateurexterne';
        } else if (user.roles.siteTeacher) {
            return 'icon-icon-formateur-site';
        } else if (user.roles.corporationTeacher) {
            return 'icon-FormateurEntreprise';
        } else if (user.roles.tuteurentreprise) {
            return 'icon-tuteurentreprise';
        } else if (user.roles.localAdmin) {
            return 'icon-adminlocal';
        } else if (user.roles.nationalAdmin) {
            return 'icon-adminnational';
        }
    }

    /**
     * Indique le nom de l'auteur du cloud
     * @returns {string} Le prénom et le nom de l'auteur du cloud
     */
    getAuthorName(): string {
        return this.cloud.owner.lastname.toUpperCase() + ' ' + this.cloud.owner.firstname;
    }

    /**
     * Indique si le cloud actuel est sélectionné
     * @returns {Boolean} True si le cloud actuel est sélectionné, faux dans le cas contraire
     */
    isCloudSelected(): boolean {
        return this.selected;
    }

    /**
     * Indique si le cloud actuel est ouvert
     * @returns {Boolean} True si le cloud actuel est ouvert, faux dans le cas contraire
     */
    isCloudOpened(): boolean {
        return this.openedCloud;
    }

    /**
     * Ouvre et affiche le cloud actuel
     */
    toggleCloud(event: MouseEvent) {
        event.stopImmediatePropagation();
        if (this.cloud.users === undefined) {
            this.getCloud$ = this.cloudService.getCloud(this.cloud.id).subscribe(
                (data: any) => {
                    for (const i in data.users) {
                        if (data.users[i].id === this.cloud.owner.id) {
                            data.users.splice(i, 1);
                        }
                    }

                    this.cloud.users = data.users;
                    this.cloud.groups = data.groups;
                    this.openedCloud = !this.openedCloud;

                    for (const i in data.groups) {
                        if (data.groups[i].users === undefined) {
                            this.getGroup$ = this.userService.getGroup(data.groups[i].id).subscribe(
                                (data_group: any) => {
                                    data.groups[i].users = data_group.users;
                                },
                                (error: HttpErrorResponse) => {
                                    this.dialogService.openErrorDialog(error.error.userMessage);
                                }
                            );
                        }
                    }
                },
                (error: HttpErrorResponse) => {
                    this.dialogService.openErrorDialog(error.error.userMessage);
                }
            );
        } else {
            this.openedCloud = !this.openedCloud;
        }
    }

    ngOnInit() {
        this.openedCloud = false;
    }

    ngOnDestroy() {
        if (this.getCloud$) {
            this.getCloud$.unsubscribe();
        }
        if (this.getGroup$) {
            this.getGroup$.unsubscribe();
        }
    }
}
