import { Component, OnInit, Input, OnDestroy } from '@angular/core';

import { DialogService } from '../../../services/dialog.service';
import { LoginService } from 'src/app/services/login.service';
import { CloudService } from 'src/app/services/cloud.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { FlashMessageService } from 'src/app/services/flash-message.service';
import { Cloud } from 'src/app/structures/cloud';

/**
 * Composant gérant l'affichage des clouds dans le menu de gauche
 */

@Component({
    selector: 'app-nav-entry',
    templateUrl: './nav-entry.component.html',
    styleUrls: ['./nav-entry.component.scss'],
    standalone: false
})
export class NavEntryComponent implements OnInit, OnDestroy {
    /**
     * @param {any} cloud le cloud qui est dans cette entree.
     * @param {number} selectedCloud l'id du cloud selectionne.
     * @param {string} type sert a savoir si l'utilisateur est autheur ou non du cloud.
     */
    @Input() cloud: any;
    @Input() type: string;
    @Input() selectedCloud: number;

    isAdmin: boolean;

    unmaskCloud$: Subscription;
    subscriptions: Subscription = new Subscription();

    constructor(
        private dialogService: DialogService,
        private loginService: LoginService,
        private cloudService: CloudService,
        private flashMessageService: FlashMessageService
    ) {}

    ngOnInit() {
        this.isAdmin = this.loginService.isAdmin();
    }

    ngOnDestroy() {
        if (this.unmaskCloud$) {
            this.unmaskCloud$.unsubscribe();
        }
        this.subscriptions.unsubscribe();
    }

    /**
     * Indique si le cloud actuel est sélectionné ou non
     * @returns {Boolean} True si le cloud est sélectionné, false sinon
     */
    isCloudSelected(): Boolean {
        return this.cloud.id === this.selectedCloud;
    }

    /**
     * Indique si le cloud actuel ne contient qu'un seul utilisateur
     * @returns {Boolean} True si le cloud ne contient qu'un seul membre, false dans le cas contraire
     */
    isOnlyMember(): Boolean {
        return this.cloud.members === 0;
    }

    /**
     * Indique si les icones doivent s'afficher
     * @returns {Boolean} True si les icones doivent  s'afficher pour l'utilisateur actuel, faux dans le cas contraire
     */
    showIconEdit(): Boolean {
        if (
            this.type === 'author' ||
            (this.type === 'member' && this.loginService.isNationalAdmin()) ||
            (this.type === 'member' && this.loginService.isLocalAdmin()) ||
            this.loginService.isCloudModerator(this.cloud.id)
        ) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Indique si les icones doivent s'afficher
     * @returns {Boolean} True si les icones doivent  s'afficher pour l'utilisateur actuel, faux dans le cas contraire
     */
    showIcons(): Boolean {
        if (
            this.type === 'author' ||
            (this.type === 'member' && this.loginService.isNationalAdmin()) ||
            (this.type === 'member' && this.loginService.isLocalAdmin())
        ) {
            return true;
        } else {
            return false;
        }
    }

    isMaskedCloud(): Boolean {
        return this.type === 'masked';
    }

    getCloudNotificationsIcon(): string {
        if (this.cloud.isMuted) {
            return 'icon-notifications';
        }
        return 'icon-notifications-barree';
    }

    getNotificationsIconTooltip(): string {
        if (this.cloud.isMuted) {
            return 'Activer les notifications';
        }
        return 'Désactiver les notifications';
    }

    toggleCloudNotifications($event: Event, cloud: Cloud): void {
        $event.stopImmediatePropagation();
        this.subscriptions.add(
            this.cloudService.toggleMuteCloud(cloud).subscribe((data) => {
                this.cloud.isMuted = !this.cloud.isMuted;
                this.flashMessageService.flash(
                    `les notifications du cloud ${cloud.name} ont été ${
                        this.cloud.isMuted ? 'désactivées' : 'activées'
                    }`
                );
            })
        );
    }

    /**
     * Ouvre la fenêtre de création de cloud
     * @param {Event} $event L'évenement Javascript émis lors du clic
     */
    openEditCloudDialog($event: Event) {
        $event.stopPropagation();
        this.dialogService.openCreateCloudDialog(this.cloud.id);
    }

    /**
     * Ouvre la fenêtre de suppression de cloud
     * @param {Event} $event L'évenement Javascript émis lors du clic
     */
    openDeleteCloudDialog($event: Event) {
        $event.stopPropagation();
        this.dialogService.openDeleteCloudDialog(this.cloud.id);
    }

    /**
     * Ouvre la fenêtre d'affichage des membres du cloud
     * @param {Event} $event L'évenement Javascript émis lors du clic
     */
    openMembersDialog($event: Event) {
        $event.stopPropagation();
        this.dialogService.openMembersDialog(this.cloud.id);
    }

    /**
     * Ouvre la fenêtre de création de cloud
     * @param {Event} $event L'évenement Javascript émis lors du clic
     */
    openMaskCloudDialog($event: Event) {
        $event.stopPropagation();
        this.dialogService.openMaskCloudDialog(this.cloud.id);
    }

    unmaskCloud($event: Event) {
        $event.stopPropagation();
        this.unmaskCloud$ = this.cloudService.unmaskCloud(this.cloud.id).subscribe(
            (data: any) => {
                this.cloudService.refreshClouds();
            },
            (error: HttpErrorResponse) => {
                this.dialogService.openErrorDialog(error.error.userMessage);
            }
        );
    }
}
