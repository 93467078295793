import { Component, OnInit, Inject, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { CloudService } from '../../services/cloud.service';

@Component({
    selector: 'app-members',
    templateUrl: './members.component.html',
    styleUrls: ['./members.component.scss'],
    standalone: false
})
export class MembersComponent implements OnInit, OnDestroy {
    /**
     * @param {EventEmitter} openErrorDialog emet un evenement si il faut ouvrir la fenetre d'erreur.
     * @param {any} cloud le cloud en question.
     * @param {number} groupNumber le nombre de groupes inscrits au cloud.
     * @param {number} learnerNumber le nombre d'apprenants inscrits au cloud.
     * @param {number} teacherNumber le nombre de tuteurs inscrits au cloud.
     */
    @Output() openErrorDialog: EventEmitter<string> = new EventEmitter();

    getCloud$: Subscription;

    cloud: any;

    groupNumber: number;
    learnerNumber: number;
    teacherNumber: number;
    roleNumber: number;

    constructor(
        public dialogRef: MatDialogRef<MembersComponent>,
        private cloudService: CloudService,
        @Inject(MAT_DIALOG_DATA) private data: any
    ) {}

    ngOnInit() {
        this.getCloud$ = this.cloudService.getCloud(this.data.cloudId).subscribe((data: any) => {
            this.cloud = data;
            const rolesTmp = [];
            let roleExisting: boolean;
            for (const i in this.cloud.roles) {
                if (this.cloud.roles[i]) {
                    roleExisting = false;
                    for (const j in rolesTmp) {
                        if (rolesTmp[j].id === this.cloud.roles[i].id) {
                            roleExisting = true;
                        }
                    }
                    if (!roleExisting) {
                        rolesTmp.push(this.cloud.roles[i]);
                    }
                }
            }
            this.cloud.roles = rolesTmp;
            this.groupNumber = data.groups.length;
            this.roleNumber = data.roles.length;
            this.learnerNumber = 0;
            this.teacherNumber = 0;
            for (const i in data.users) {
                if (data.users[i].roles.learner || data.users[i].roles.prospect) {
                    this.learnerNumber++;
                } else {
                    this.teacherNumber++;
                }
            }
        });
    }

    ngOnDestroy() {
        this.getCloud$.unsubscribe();
    }

    /**
     * Ferme la fenetre.
     */
    closeDialog() {
        this.dialogRef.close();
    }
}
