import { Component, OnInit, Inject, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { CloudService } from '../../services/cloud.service';
import { LoginService } from '../../services/login.service';
import { Subscription } from 'rxjs';
import { Cloud } from 'src/app/structures/cloud';

@Component({
    selector: 'app-shared-clouds',
    templateUrl: './shared-clouds.component.html',
    styleUrls: ['./shared-clouds.component.scss'],
    standalone: false
})
export class SharedCloudsComponent implements OnInit, OnDestroy {
    isLoaded = false;
    sharedCloudsLoaded = false;
    sharedClouds: Array<any>;
    adminCloudsLoaded = false;
    adminCloudsIdsList: Array<number>;
    clouds: Array<any>;
    headerText: string;

    private subscriptions = new Subscription();

    @Output() openErrorDialog: EventEmitter<string> = new EventEmitter();

    constructor(
        private router: Router,
        private cloudService: CloudService,
        private loginService: LoginService,
        public dialogRef: MatDialogRef<SharedCloudsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    /**
     * Indique si le menu de sélection de structure doit s'afficher pour l'utilisateur actuel
     * @returns {Boolean} True si le menu doit s'afficher, false dans le cas contraire
     */
    showStructuresDropdown(): boolean {
        return this.loginService.isNationalAdmin();
    }

    openCreateCloud() {
        const queryParams = [];

        queryParams['new'] = 'true';
        queryParams[this.data.type] = this.data.id;

        this.router.navigate(['.'], { queryParams: queryParams });

        this.dialogRef.close();
    }

    openSharedCloud(cloud: Cloud): void {
        this.dialogRef.close(cloud);
    }

    /**
     * Ferme la fenêtre actuelle
     */
    closeDialog() {
        this.dialogRef.close();
    }

    ngOnInit() {
        const params = {
            type: this.data.type,
            instanceid: this.data.id
        };

        this.subscriptions.add(
            this.cloudService.getSharedClouds(params).subscribe((data: any) => {
                this.sharedClouds = data;
                this.sharedCloudsLoaded = true;
                this.sharedCloudsReconciliation();
            })
        );

        this.subscriptions.add(
            this.cloudService.getCloudsSubject().subscribe((data: any) => {
                if (data.mine && data.member && data.history && data.hidden) {
                    this.adminCloudsIdsList = [
                        ...data.mine.map((cloud) => cloud.id),
                        ...data.member.map((cloud) => cloud.id)
                    ];
                    this.adminCloudsLoaded = true;
                    this.sharedCloudsReconciliation();
                }
            })
        );

        if (this.data.type === 'group') {
            this.headerText = `Clouds partagés avec ce groupe :`;
        } else if (this.data.type === 'user') {
            this.headerText = `Clouds partagés avec cet utilisateur :`;
        }
    }

    sharedCloudsReconciliation() {
        if (this.adminCloudsLoaded && this.sharedCloudsLoaded) {
            this.clouds = this.sharedClouds.filter((cloud) =>
                this.adminCloudsIdsList.find((id) => id === cloud.id)
            );
            if (this.clouds.length === 0) {
                this.openCreateCloud();
            }
            this.isLoaded = true;
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
